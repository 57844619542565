import React from 'react'
import styled from 'styled-components'

const ProjectHeaderWrapper = styled.div`
  display: flex;
  height: 45em;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  // max-width: 1400px;
  z-index: -1;
  margin: auto;
`

const Claim = styled.div`
  align-self: center;
  text-align: center;
`

const Title = styled.div`
  color: #fff;
  font-size: 3em;
  line-height: 1.3em;
`

const Category = styled.div`
  text-transform: uppercase;
  font-size: 0.8em;
  letter-spacing: 0.3em;
  text-align: center;
  color: #fff;
`

const ProjectHeader = ({ header, title, category }) => (
  <div>
    <ProjectHeaderWrapper style={{ backgroundImage: `url(${header})` }}>
      <div />
      <Claim>
        <Category>{category}</Category>
        <Title>{title}</Title>
      </Claim>
      <div />
    </ProjectHeaderWrapper>
  </div>
)

export default ProjectHeader
