import React from 'react'
import Helmet from 'react-helmet'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { graphql, Link } from 'gatsby'

import Layout from '../components/layout'
import keywords from '../utils/keywords'
import { media } from '../utils/style-utils'

import CTALink from '../components/cta-link'
import PageWrapper from '../components/page-wrapper'
import ProjectHeader from '../components/project-header'

const ProjectDescription = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  ${media.mobileOnly`
    flex-direction: column;
    align-items: center;
  `};
`

const Title = styled.h1`
  width: 50%;
  font-size: 1em;
  text-transform: uppercase;
  letter-spacing: 0.3em;
  color: #2ea9d6;
  font-weight: normal;
  ${media.mobileOnly`
    width: 100%;
    font-size: 1.5em;
    text-align: center;
  `};
`

const Description = styled.div`
  width: 50%;
  font-size: 1em;
  color: #4e4e4d;
  line-height: 1.5em;
  ${media.mobileOnly`
    width: 100%;
    text-align: center;
    margin-top: 1em;
  `};
`

const ProjectImages = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 2em -0.5rem 0 -0.5rem;
`

const ImageRow = styled.div`
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  align-items: strech;
  ${media.mobileOnly`
    flex-direction: column;
    margin: 0
  `};
  & .gatsby-image-outer-wrapper {
    flex: 1;
    margin: 0.25em;
  }
`

const CTAArea = styled.div`
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2em;
  & * {
    margin: 1em 0;
  }
`

const NextProject = styled(Link)`
  color: #2ea9d6;
  transition: all 0.2s ease-in;
  &:visited,
  &:link {
    color: #2ea9d6;
  }
  &:hover {
    color: #f49303;
  }
`

const Intro = styled.div`
  margin-bottom: 2em;
`

const Fair = styled.div`
  letter-spacing: 0.2em;
  font-size: 0.9em;
  color: #2ea9d6;
`

export default ({ data, location }) => {
	const compare = (a, b) => {
		if (a.row < b.row) return -1
		if (a.row > b.row) return 1
		if (a.col < b.col) return -1
		if (a.col > b.col) return 1
		return 0
	}

	const arangeImageArray = data => {
		let maxRows = 0
		let sorted = []
		let arange = []
		data.forEach(({ node }) => {
			const row = parseInt(node.name.split('_')[0])
			const col = parseInt(node.name.split('_')[1])

			if (row > maxRows) {
				maxRows = row
			}
			sorted.push({ ...node, col: col, row: row })
		})

		sorted.sort(compare)
		for (let i = 1; i <= maxRows; i++) {
			arange.push(sorted.filter(sorted => sorted.row === i))
		}

		return arange
	}

	console.dir(data.images.edges)
	const arangedImageArray = arangeImageArray(data.images.edges)

	const nextProject = () => {
		for (let i = 0; i < data.projects.edges.length; i++) {
			if (
				data.projects.edges[i].node.fields.slug ===
				data.markdown.childMarkdownRemark.fields.slug
			) {
				return data.projects.edges[(i + 1) % data.projects.edges.length].node
					.fields.slug
			}
		}
	}

	return (
		<Layout location={location}>
			<div>
				<Helmet
					title={
						data.site.siteMetadata.title +
						'-' +
						data.markdown.childMarkdownRemark.frontmatter.title
					}
					meta={[
						{
							name: 'description',
							content:
								'Joachim Neuroth GmbH Messemarketing - Projektbeispiel: ' +
								data.markdown.childMarkdownRemark.frontmatter.title,
						},
						{
							name: 'keywords',
							content:
								keywords +
								', ' +
								data.markdown.childMarkdownRemark.frontmatter.title,
						},
					]}
				/>
				<ProjectHeader
					title={data.markdown.childMarkdownRemark.frontmatter.title}
					header={data.header.childImageSharp.sizes.src}
					category={data.markdown.childMarkdownRemark.frontmatter.category}
				/>
				<PageWrapper style={{ paddingTop: '50em', marginTop: '-9em' }}>
					<ProjectDescription>
						<Title>{data.markdown.childMarkdownRemark.frontmatter.title}</Title>
						<Description>
							<Intro
								dangerouslySetInnerHTML={{
									__html: data.showroom.childMarkdownRemark.html,
								}}
							/>
							<Fair
								dangerouslySetInnerHTML={{
									__html: data.markdown.childMarkdownRemark.html,
								}}
							/>
						</Description>
					</ProjectDescription>
					<ProjectImages>
						{arangedImageArray.map((entry, index) => (
							<ImageRow key={index} numCols={entry.length}>
								{entry.map(image => (
									<Img
										key={image.name}
										fluid={image.childImageSharp.fluid}
										style={{ margin: '0.25em', flex: '1' }}
									/>
								))}
							</ImageRow>
						))}
					</ProjectImages>
					<CTAArea>
						<CTALink href={'mailto:neuroth@neuroth-messemarketing.de'}>
							Haben wir Ihr Interesse geweckt?
						</CTALink>
						<NextProject to={nextProject()} style={{ textAlign: 'center' }}>
							Nächstes Projekt anschauen
						</NextProject>
					</CTAArea>
				</PageWrapper>
			</div>
		</Layout>
	)
}

export const projectQuery = graphql`
  query SingleProjectQuery(
    $markdown: String!
    $showroom: String!
    $header: String!
    $images: String!
  ) {
    markdown: file(relativePath: { eq: $markdown }) {
      childMarkdownRemark {
        html
        frontmatter {
          title
          category
        }
        fields {
          slug
        }
      }
    }
    showroom: file(relativePath: { eq: $showroom }) {
      childMarkdownRemark {
        html
      }
    }
    site {
      siteMetadata {
        title
      }
    }
    header: file(relativePath: { eq: $header }) {
      childImageSharp {
        sizes(maxWidth: 2560, quality: 85) {
          ...GatsbyImageSharpSizes_withWebp
        }
      }
    }
    images: allFile(filter: { relativeDirectory: { eq: $images } }) {
      edges {
        node {
          name
          absolutePath
          childImageSharp {
            fluid(maxWidth: 1440, quality: 85) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    projects: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/.*(/projects/).*(/)(showroom.md)/" }
        frontmatter: { hidden: { ne: true } }
      }
      sort: { fields: [id], order: ASC }
    ) {
      edges {
        node {
          fields {
            slug
          }
        }
      }
    }
  }
`
